<template>
  <vs-card>
    <doctor-edit
      fetchClinicsBy="franchise"
      :canDelete="true" />
  </vs-card>
</template>
<script>

  import doctorEdit from '../../components/doctors/editDoctor.vue'

  export default {
    components: {
      doctorEdit
    },
    data:()=>({
    })
  }
</script>
